<template>
  <div class="search">

    <el-card style="margin-bottom:12px">
      <div class="flex">
        <div class="cTitle" @click="more = !more">
          <i v-if="!more" class="el-icon-caret-right"></i>
          <i v-if="more" class="el-icon-caret-bottom"></i>
          高级筛选
        </div>
        <div class="btnAction">
          <el-input v-model="searchForm.compName" placeholder="请输入供应商名称" style="width:160px;margin-right:8px"></el-input>
          <el-button type="primary" plain @click="getDataList" size="mini">搜索</el-button>
        </div>
      </div>
      <div class="flex" style="margin-top: 10px;" v-if="more">
        <el-input v-model="searchForm.licensePlate" placeholder="请输入车牌号" style="width:160px;margin-right:8px"></el-input>
        <el-input v-model="searchForm.goodsName" placeholder="请输入物料名称" style="width:160px;margin-right:8px"></el-input>
     
     
      </div>
    </el-card>

    <el-card>
      <div class="flex">
        <div class="cTitle">车辆过磅信息</div>
        <div class="btnAction">
          <el-button type="primary" @click="exportDataClick('select')" icon="el-icon-c-scale-to-original">导出已选</el-button>
          <!-- <el-button type="primary" @click="exportDataClick('all')" icon="el-icon-c-scale-to-original">导出全部</el-button>
         -->
        </div>
      </div>
      <div style="margin-top:16px">

        <vxe-table align="center" ref="table" :export-config="{
          types: ['csv'],
          isHeader: true,
        }" :print-config="{}" stripe size="medium" :height="height - 147 - 50 - 95" :loading="loading"
          :column-config="{ resizable: true }" :row-config="{ isHover: true }" :data="data">

          <vxe-column fixed="left" type="checkbox" width="40"></vxe-column>

          <vxe-column width="120" field="compName" title="供应商名称">
            <template #default="{ row }">
              <span>{{ row.compName }}</span>
            </template>
          </vxe-column>

          <vxe-column width="120" field="goodItems" title="物料名称">
            <template #default="{ row }">
              <span>{{ row.goodsName }}</span>
            </template>
          </vxe-column>

          <vxe-column width="120" field="licensePlate" title="车牌号">
            <template #default="{ row }">
              <span>{{ row.licensePlate }}</span>
            </template>
          </vxe-column>

          <vxe-column width="90" field="plateColor" title="车牌颜色">
            <template #default="{ row }">
              <span>{{ row.plateColor }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="driverName" title="司机姓名">
            <template #default="{ row }">
              <span>{{ row.driverName }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="driverMobile" title="司机手机号">
            <template #default="{ row }">
              <span>{{ row.driverMobile }}</span>
            </template>
          </vxe-column>
          <vxe-column width="130" field="orderStatus" title="订单状态">
            <template #default="{ row }">
              <el-tag v-if="row.orderStatus == 0">司机已扫码</el-tag>
              <el-tag v-if="row.orderStatus == 1">一次过磅完成</el-tag>
              <el-tag v-if="row.orderStatus == 2" type="success">订单完成</el-tag>

            </template>
          </vxe-column>
          <vxe-column width="120" field="plateColor" title="过毛时间">
            <template #default="{ row }">
              <span>{{ row.grossTime }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="plateColor" title="过皮时间">
            <template #default="{ row }">
              <span>{{ row.tareTime }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="plateColor" title="验收人确认">
            <template #default="{ row }">
              <span>{{ row.checkOperateName }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="plateColor" title="卸货人确认">
            <template #default="{ row }">
              <span>{{ row.unLoadOperateName }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="grossWeight" title="毛重">
            <template #default="{ row }">
              <span>{{ row.grossWeight }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="tareWeight" title="皮重">
            <template #default="{ row }">
              <span>{{ row.tareWeight }}</span>
            </template>
          </vxe-column>
          <vxe-column width="120" field="netWeight" title="净重">
            <template #default="{ row }">
              <span>{{ row.netWeight }}</span>
            </template>
          </vxe-column>

          <vxe-column title="过毛车图片" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.grossLicenseName,row)" v-if="row.grossLicenseName">
                  <img width="30" height="30"  src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <vxe-column title="过毛车牌图片" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tarePlateName,row)" v-if="row.tarePlateName">
                  <img width="30" height="30"  src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>




          <vxe-column title="过皮车图片" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tareLicenseName,row)" v-if="row.tareLicenseName">
                  <img width="30" height="30"  src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <vxe-column title="过皮车牌图片" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.grossPlateName,row)" v-if="row.grossPlateName">
                  <img width="30" height="30"  src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>







          <vxe-column title="过毛车头相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.grossCaptureHead,row)" v-if="row.grossCaptureHead">
                  <img width="30" height="30"  src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>


          <vxe-column title="过毛车尾相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.grossCaptureTail,row)" v-if="row.grossCaptureTail">
                  <img width="30" height="30" referrerpolicy="no-referrer" src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <vxe-column title="过毛车仓相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.grossCaptureWare,row)" v-if="row.grossCaptureWare">
                  <img width="30" height="30" referrerpolicy="no-referrer"  src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <!-- <vxe-column title="过毛车身相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.grossCaptureBody)" v-if="row.grossCaptureBody">
                  <img width="30" height="30" :src="row.grossCaptureBody" />
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column> -->

          <vxe-column title="过皮车头相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tareCaptureHead,row)" v-if="row.tareCaptureHead">
                  <img width="30" height="30" referrerpolicy="no-referrer" src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <vxe-column title="过皮车尾相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tareCaptureTail,row)" v-if="row.tareCaptureTail">
                  <img width="30" height="30" referrerpolicy="no-referrer" src="@/assets/imgs.png" />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <vxe-column title="过皮车仓相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tareCaptureWare,row)" v-if="row.tareCaptureWare">
                  <img width="30" height="30" referrerpolicy="no-referrer" src="@/assets/imgs.png"  />
                  <p style="color: blue;font-weight: font-weight: 100;">点击查看</p>
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column>

          <!-- <vxe-column title="过皮车身相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tareCaptureBody)" v-if="row.tareCaptureBody">
                  <img width="30" height="30" :src="row.tareCaptureBody" />
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column> -->

          <!-- <vxe-column title="过皮磅房相机抓拍" width="120">
            <template #default="{ row }">
              <div style="display:flex;justify-self: center;justify-content: center;cursor: pointer;">
                <div @click="preImg(row.tareCapturePoundRoom)" v-if="row.tareCapturePoundRoom">
                  <img width="30" height="30" :src="row.tareCapturePoundRoom" />
                </div>
                <div v-else>
                  <img width="30" height="30" src="@/assets/imgs.png" />
                </div>
                <div style="margin-left:6px"></div>
              </div>
            </template>
          </vxe-column> -->

          <!--        <vxe-column field="yjFinishTime" title="预期完成时间" width="250"></vxe-column>-->
          <vxe-column fixed="right" title="操作" width="90">
            <template #default="{ row }">
              <div style="display:flex">
            <el-button size="mini"  plain  type="danger"  v-if="row.orderStatus != 2"   @click="remove(row)">删除</el-button>
              </div>

            </template>
          </vxe-column>


        </vxe-table>
        <vxe-pager :loading="loading" :current-page="page.currentPage" :page-size="page.pageSize" :total="page.total"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="handlePageChange1">
        </vxe-pager>






      </div>


      <div>
        <el-image-viewer :on-close="closeImgViewer" v-if="dialogPics" :mask-closable="false" :initial-index="initialIndex" :url-list="imgList" />
      </div>
    </el-card>


    <!-- 自定义导出数据 -->
    <Modal v-model="exportModalVisible" :title="exportTitle" :loading="loadingExport" @on-ok="exportCustomData">
      <Form ref="exportForm" :label-width="100">
        <FormItem label="导出文件名">
          <Input v-model="filename" />
        </FormItem>
        <FormItem label="自定义导出列">
          <CheckboxGroup v-model="chooseColumns">
            <Checkbox v-for="(item, i) in exportColumns" :label="item.title" :key="i" :value="item.checked"
              :disabled="item.disabled"></Checkbox>
          </CheckboxGroup>
        </FormItem>
      </Form>
    </Modal>


  </div>
</template>

<script>
// 根据你的实际请求api.js位置路径修改
import { getRecordAByCondition,delRecord } from "@/api/index";
import { exportColumn } from "./exportColumn";
import excel from "@/libs/excel";
// 根据你的实际添加编辑组件位置路径修改
import { shortcuts } from "@/libs/shortcuts";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: "buildingConfig",
  components: {
    VueQrcode, ElImageViewer
  },
  data() {
    return {
      initialIndex:0,
      exportData: [],
      exportModalVisible: false,
      exportColumns: exportColumn,
      chooseColumns: [],
      filename: "过磅信息数据",
      exportTitle: "确认导出",
      exportType: "",
      loadingExport: true,



      dialogPics: false,
      imgList: [],

      more: false,
      selectItem: {},
      height: 600,
      modalVisibleWeiXin: false,
      tableSize: "default",
      openTip: true, // 显示提示
      showType: "0", // 添加或编辑标识
      showDrawer: false, // 显示添加编辑抽屉
      loading: true, // 表单加载状态
      searchForm: { // 搜索框初始化对象
        userType: 1,
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        sort: "createTime", // 默认排序字段
        order: "desc", // 默认排序方式
      },
      selectList: [], // 多选数据
      form: {},
      data: [], // 表单数据
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      qrcodeText: "",

    };
  },
  methods: {

    exportDataClick(t) {
      if (t == 'select') {
        let selectRecords = this.$refs.table.getCheckboxRecords()
        if(selectRecords == 0){
          this.$notify.warning({
            message:"请至少选一个"
          })
          return;
        }
        this.exportData = selectRecords;
        this.exportType = "part";
        this.exportModalVisible = true;
        this.exportTitle =
          "确认导出 " + this.exportData.length + " 条数据";




      } else {

      }
    },
    exportCustomData() {
      if (this.filename == "") {
        this.filename = "用户数据";
      }
      // 判断勾选导出列
      let exportColumns = [];
      this.exportColumns.forEach((e) => {
        this.chooseColumns.forEach((c) => {
          if (e.title == c && !e.disabled) {
            exportColumns.push(e);
          }
        });
      });
      this.exportModalVisible = false;
      let title = [];
      let key = [];
      exportColumns.forEach((e) => {
        title.push(e.title);
        key.push(e.key);
      });
      const params = {
        title: title,
        key: key,
        data: this.exportData,
        autoWidth: true,
        filename: this.filename,
      };
      excel.export_array_to_excel(params);
    },



    preImg(v,row) {
      v = v.replace('http://183.129.176.30:8081/','https://db.njfjkj.com/')
       let ar = [v]
      this.initialIndex = 0
      this.imgList = ar
      this.dialogPics = true
    },
    closeImgViewer() {
      this.dialogPics = false;
      const m = (e) => { e.preventDefault() };
      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
    },
    init() {
      this.getDataList();
            let array = [];
      this.exportColumns.forEach((e) => {
        array.push(e.title);
      });
      this.chooseColumns = array;
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.page.currentPage = currentPage
      this.page.pageSize = pageSize
      this.getDataList()
    },
    getDataList() {
      this.loading = true;

      getRecordAByCondition(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize
      }, this.searchForm)).then(res => {
        this.loading = false;
        if (res.success) {
          this.data = res.result.records;
          this.page.total = res.result.total
        }
      });
    },
    add() {
      this.showType = "2";
      this.showDrawer = true;
    },
    edit(v) {
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.form = data;
      this.showType = "1";
      this.showDrawer = true;
    },
    remove(v) {
      this.$Modal.confirm({
        title: "确认删除",
        // 记得确认修改此处
        content: "您确认要删除该条数据?",
        loading: true,
        onOk: () => {
          // 删除
          delRecord({ ids: v.id }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    },
    delAll() {
   
    }
  },
  mounted() {
    this.init();

    this.height = (window.innerHeight)
    window.onresize = () => {
      return (() => {
        this.height = (window.innerHeight)
      })()
    }
  }
};
</script>
<style lang="less">
@import "@/styles/table-common.less";

.flex {
  display: flex;
}

.cTitle {
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 800;
}

.btnAction {
  flex: 1;
  text-align: right;
}

.vxe-pager.is--perfect .vxe-pager--num-btn:not(.is--disabled).is--active {
  background-color: #b6986d;
}

.is--checked.vxe-checkbox,
.is--checked.vxe-checkbox .vxe-checkbox--icon,
.is--checked.vxe-custom--option,
.is--checked.vxe-custom--option .vxe-checkbox--icon,
.is--checked.vxe-export--panel-column-option,
.is--checked.vxe-export--panel-column-option .vxe-checkbox--icon,
.is--checked.vxe-table--filter-option,
.is--checked.vxe-table--filter-option .vxe-checkbox--icon,
.is--indeterminate.vxe-checkbox,
.is--indeterminate.vxe-checkbox .vxe-checkbox--icon,
.is--indeterminate.vxe-custom--option,
.is--indeterminate.vxe-custom--option .vxe-checkbox--icon,
.is--indeterminate.vxe-export--panel-column-option,
.is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon,
.is--indeterminate.vxe-table--filter-option,
.is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon,
.vxe-table--render-default .is--checked.vxe-cell--checkbox,
.vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon,
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox,
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #b6986d
}
</style>